import './App.css';

function App() {
  return (
    <div className="min-h-screen bg-white matrix flex flex-col">
        <div className="mx-auto max-w-screen-2xl w-full flex-1 py-8 flex flex-col gap-4">
            <div className="flex items-center">
                <img className="h-8" src="/img/BALLIN.jpg" alt="BALLIN.jepg" />
                <div className="flex-grow" />
                <a href="mailto:ceo@ballin.biz" className="px-8 py-2 bg-black text-white font-bold">Contact Us</a>
            </div>
            <hr className="border-2 border-black rounded" />
            <img className="w-[300px] border-2 border-black" src="/img/banner.png" />
            <p className="bg-white">Ballin Technologies is the leading</p>
            <div className="py-8">
                <p className="bg-white mb-4">The Leading:</p>
                <div className="grid grid-cols-4 gap-4">
                    <div className="border-2 p-4 border-black bg-white flex flex-col items-center text-center">
                        <div className="pb-4">
                            <p className="font-bold">UNNAMED ROCKSTAR TITLE MULTIPLAYER MOD DDoS PROTECTION</p>
                            <ul>
                                <li>L4 Protection</li>
                                <li>L7 Protection</li>
                                <li>The leading</li>
                                <li>Best in class XDP kernel level filtering</li>
                                <li>Hyper scalable</li>
                                <li>Caches information endpoints</li>
                                <li>Full return packet validation</li>
                            </ul>
                        </div>
                        <a href="mailto:ceo@ballin.biz"><img className="h-16 -my-6" alt="buy.png" src="/img/buy.png" /></a>
                    </div>
                    <div className="border-2 p-4 border-black bg-white flex flex-col items-center text-center">
                        <div className="pb-4">
                            <p className="font-bold">REMOTE NETWORK PROTECTION</p>
                            <ul>
                                <li>L4 Protection</li>
                                <li>L7 Protection</li>
                                <li>The leading</li>
                            </ul>
                        </div>
                        <a href="mailto:ceo@ballin.biz"><img className="h-16 -my-6" alt="buy.png" src="/img/buy.png" /></a>
                    </div>
                </div>
            </div>
            <div className="flex-grow" />
            <div>
                <p className="bg-white">&copy; 1999 - 2027 BALLIN TECHNOLOGIES inc. contact: Ceo@ballin.biz. <a className="text-blue-600 underline hover:text-blue-800" href="#">+1 (888) 461 9796</a></p>
                <p className="bg-white">corporate office: kalkofnsvegur 2., 101, reykjavík, Republic of iceland </p>
                <p className="bg-white">branch office: kBrattørkaia 17A, 7010 trondheim, kingdom Of norway</p>
            </div>
        </div>
    </div>
  );
}

export default App;
